import React from "react";
import footerLogo from "../../assets/images/footer-logo.svg";
import { phoneNumberConstant  } from "../Constant/Constants.Mobile";
import { phoneNumberConstantCall  } from "../Constant/Constants.Mobile";

export function FooterMobile() {
  return (
    <div className="py-8 bg-primary-blue">
      <div className="md:w-10/12 mx-auto px-6 md:px-0">
        <div className="mb-12">
          <img src={footerLogo} alt={footerLogo} />
        </div>
        <div className="flex flex-col sm:flex-row justify-start sm:justify-between mb-8">
          <div>
            <p className="font-bold text-lg text-white mb-4 sm:mb-0 pt-7">
              Call to Book Service
              <span className="text-orange">
                <a href={ phoneNumberConstantCall }> { phoneNumberConstant }</a>
              </span>
            </p>
          </div>
          <div>
            <p className="font-bold text-lg text-white mb-4 text-left">
              Social media
            </p>
            <ul className="flex gap-3">
              <li>
                <a
                  className="bg-white rounded-md h-8 w-8 grid place-items-center text-orange"
                  href="https://www.instagram.com/nedshome/"
                  target="_blank"
                >
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  className="bg-white rounded-md h-8 w-8 grid place-items-center text-orange cursor-pointer"
                  href="https://www.youtube.com/watch?v=7xeTeOY4_Qo"
                  target="_blank"
                >
                  <i className="fa-brands fa-youtube"></i>
                </a>
              </li>
              <li>
                <a
                  className="bg-white rounded-md h-8 w-8 grid place-items-center text-orange"
                  href="https://www.facebook.com/NedStevensGutterCleaning/"
                  target="_blank"
                >
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="flex flex-col md:flex-row justify-center md:justify-between mt-7 pb-36 sm:pb-0">
          <p className="text-white text-sm font-inter text-center mb-4 md:mb-0">
            Copyright © 2023 Ned's Home. All Rights Reserved
          </p>
          <ul className="flex items-center gap-20 mx-auto md:mx-0">
            <li>
              <a
                className="text-white text-sm font-inter"
                href="https://nedshome.com/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                className="text-white text-sm font-inter"
                href="https://nedshome.com/terms-of-use"
                target="_blank"
              >
                Terms and conditions
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

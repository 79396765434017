import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const page =
    (WrappedComponent) =>
        ({ staticContext }) => {
            const location = useLocation();

            let initData = null;
            if (staticContext) {
                initData = staticContext.data;
            } else if (window.__ROUTE_DATA__) {
                initData = window.__ROUTE_DATA__;
                delete window.__ROUTE_DATA__;
            }

            if (!staticContext) {
                useEffect(() => {
                    if (!initData) {
                        fetch(`data${location.pathname}`)
                            .then((r) => r.json())
                            .then(setPageData);
                    }
                }, [location]);
            }

            const [pageData, setPageData] = useState(initData);
            const loadGtagScript = () => {
                const script = document.createElement("script");
                script.src = `https://www.googletagmanager.com/gtag/js?id=G-EWF0CPM3GH`;
                script.async = true;
                document.body.appendChild(script);

                script.onload = () => {
                    window.dataLayer = window.dataLayer || [];
                    function gtag() {
                        window.dataLayer.push(arguments);
                    }
                    window.gtag = gtag;
                    gtag("js", new Date());
                    gtag("config", "G-EWF0CPM3GH");
                };
            };
            const excludedPaths = ["/my-services", "/address/my", "/payment/history", "/payment/savedcards", "/my-home-services", "/customer-support", "/my-profile"];
            useEffect(() => {
                if (!excludedPaths.includes(location.pathname))
                loadGtagScript()
            }, [])
            return (
                pageData && <WrappedComponent pageData={pageData}></WrappedComponent>
            );
        };

export default page;

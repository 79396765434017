import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import "./webUserVerification.css";
import featuredIcon from "../../assets/images/featured-icon-2.svg";

export default function WebUserVerificationNew({
  setOpen,
  phoneNumber,
  spinOpen,
  userType,
  redirectNeed,
  pageData,
}) {
  const ValidCodeRgex = /^\d{6}$/;
  const [verificationCode, setVerificationCode] = useState({
    code: "",
    error: "",
  });
  const [apiError, setApiError] = useState("");
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  function verifyCode() {
    const obj = {
      ...verificationCode,
      code: verificationCode.code,
      error: !verificationCode.code
        ? "Please Enter Code"
        : !ValidCodeRgex.test(verificationCode.code)
        ? "Invalid Code!"
        : "",
    };

    setVerificationCode(obj);

    if (obj.code && !obj.error) {
      spinOpen(true);
      validateOtpFun();
    }
  }

  async function validateOtpFun() {
    let bodyobj = {
      phoneNumber: phoneNumber,
      otp: verificationCode.code,
      User: userType,
    };

    if (redirectNeed === true) {
      bodyobj["isBypassNeeded"] = true;
      bodyobj["ServiceTypeFromURL"] =
        pageData?.inputValues?.serviceTypeReceivedFromURL;
      bodyobj["userInputs"] = {
        ...pageData?.inputValues?.userDetails,
        zipcode: pageData?.inputValues?.zipCode,
        Phonenumber: pageData?.inputValues?.phoneNumber,
      };
    }

    try {
      const response = await fetch(`/api/Guest/validateOTP`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyobj),
      });
      if (!response.ok) {
        const text = await response.text();
        setApiError(text);
        spinOpen(false);
      }

      const data = await response.json();
      if (data?.responseCode === 200) {
        if (data?.responseMsg === "Success") {
          if (redirectNeed === true) {
            window.location.href = "/book-service";
          } else {
            window.location.href = "/tell-us-about-you";
          }
        }
      }
      spinOpen(false);
    } catch (err) {
      spinOpen(false);
    }
  }

  useEffect(() => {
    setApiError("");
  }, [verificationCode.code]);

  async function resendeOtp() {
    spinOpen(true);
    try {
      const response = await fetch(
        `/api/Guest/generateOTP?phonenumber=${phoneNumber}`,
        { method: "GET" }
      );
      if (!response.ok) {
        if (response.status === 400) {
          const text = await response.text();
          setApiError(text);
        }
        spinOpen(false);
      }

      const data = await response.json();
      if (data?.responseCode === 200) {
        setMinutes(1);
        setSeconds(30);
      }
      spinOpen(false);
    } catch (err) {
      spinOpen(false);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <div
      id="popup-modal-2"
      tabindex="-1"
      className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      style={{ display: "flex", backgroundColor: "rgba(0, 0, 0, 0.26)" }}
    >
      <div className="relative p-4 w-full max-w-xl max-h-full">
        <div className="relative bg-white rounded-lg shadow px-4">
          <div className="bg-white md:p-5 p-4 rounded-lg text-center">
            <img className="mx-auto" src={featuredIcon} alt={featuredIcon} />
            <h2 className="text-lg font-extrabold mt-3 mb-1">
              PHONE VERIFICATION
            </h2>
            <h3 className="mb-5 text-xs font-medium font-inter text-gray-700 leading-tight">
              Please enter the 6-digit verification code that was sent to your
              phone number.{" "}
            </h3>
            <div className="items-center gap-3">
              <OtpInput
                containerStyle="otp_div gap-3"
                inputStyle="border border-orange p-0 font-semibold sm:w-12 sm:h-12 w-10 h-10 flex items-center justify-center rounded-lg text-2xl text-orange font-inter"
                value={verificationCode.code}
                onChange={(e) => {
                  setVerificationCode({
                    code: e,
                    error: !e
                      ? "Please Enter Code"
                      : !ValidCodeRgex.test(e)
                      ? "Invalid Code!"
                      : "",
                  });
                }}
                shouldAutoFocus
                numInputs={6}
                inputType="tel"
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <label
              className="text-sm text-orange "
              style={{
                display: "block",
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              {verificationCode.error}
            </label>
            <label
              className="text-sm text-orange "
              style={{
                display: "block",
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              {apiError}
            </label>

            <h3 className="mb-3 mt-5 text-xs font-medium font-inter text-gray-500 leading-tight">
              Didn’t get a code?{" "}
              <button
                className={`underline ${
                  seconds > 0 || minutes > 0 ? "text-gray-700" : ""
                }`}
                disabled={seconds > 0 || minutes > 0}
                onClick={() => resendeOtp()}
              >
                Click to resend.
              </button>
            </h3>
            {(seconds > 0 || minutes > 0) && (
              <h3 className="mt-3 mb-5 text-xs font-medium font-inter text-gray-700 leading-tight">
                Time remaining{" "}
                <span>
                  {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </span>
              </h3>
            )}
            <div className="grid grid-cols-2 gap-5 mt-6 mb-2">
              <button className="back-btn px-12" onClick={() => setOpen(false)}>
                CANCEL
              </button>
              <button
                className="btn-primary px-12"
                onClick={() => verifyCode()}
              >
                VERIFY
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import homeIconHeader from "../../assets/images/home-icon-header.svg";
import logo from "../../assets/images/logo.svg";
import "./header.css";
import { logoutUser } from "../utils/Logout";

export function MainHeaderMobile({ withAccount = false }) {
  const [accountToggle, setAccountToggle] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setAccountToggle(false);
      }
    }

    if (accountToggle) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [accountToggle]);

  return (
    <div>
      {withAccount ? (
        <nav className="mob-header py-3 fixed-header px-6 flex justify-between">
          <a
            style={{ cursor: "pointer" }}
            onClick={() => (window.location.href = "/my-home-services")}
          >
            <img src={logo} alt={logo} />
          </a>
          <div className="flex flex-row items-start justify-end relative">
            <div className="border border-orange p-2 rounded-md">
              <button
                type="button"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
                onClick={() => setAccountToggle(!accountToggle)}
                className="flex items-center justify-end"
                href="#"
              >
                <p className="font-bold text-lg text-orange mb-0 leading-2 sm:order-2">
                  My Home services
                </p>
                <img
                  className="ms-2 sm:me-2"
                  src={homeIconHeader}
                  alt={homeIconHeader}
                />
              </button>
              {
                <div
                  className="dropdown-content"
                  ref={dropdownRef}
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabIndex="-1"
                  style={{
                    overflow: "hidden",
                    height: accountToggle ? "110px" : "0px",
                    transition: "height .4s",
                    marginTop: "0px",
                    right: "1.5rem",
                  }}
                >
                  <div className="py-1" role="none">
                    <button
                      className="nav-dropdown-item font-inter"
                      role="menuitem"
                      tabIndex="-1"
                      id="menu-item-0"
                      onClick={() => (window.location.href = "/my-profile")}
                    >
                      My Profile
                    </button>
                    <hr />
                    <button
                      className="nav-dropdown-item font-inter"
                      role="menuitem"
                      tabIndex="-1"
                      id="menu-item-2"
                      onClick={() => (window.location.href = "/address/my")}
                    >
                      My Addresses
                    </button>
                    <hr />
                    <button
                      className="nav-dropdown-item font-inter"
                      role="menuitem"
                      tabIndex="-1"
                      id="menu-item-3"
                      onClick={() => logoutUser()}
                    >
                      Log Out
                    </button>
                  </div>
                </div>
              }
            </div>
          </div>
        </nav>
      ) : (
        <nav className="mob-header py-3 fixed-header px-6 flex justify-between">
          <a
            style={{ cursor: "pointer" }}
            onClick={() => (window.location.href = "/")}
          >
            <img src={logo} alt={logo} />
          </a>
          <div className="flex flex-row items-start justify-end relative">
            <div className="border border-orange p-2 rounded-md">
              <button
                className="flex items-center justify-end"
                onClick={() => (window.location.href = "/login")}
              >
                <p className="font-bold text-lg text-orange mb-0 leading-2 sm:order-2">
                  My Home services
                </p>
                <img
                  className="ms-2 sm:me-2"
                  src={homeIconHeader}
                  alt={homeIconHeader}
                />
              </button>
            </div>
          </div>
        </nav>
      )}
    </div>
  );
}

export const AppMessages = {
  zip_invalid: "Invalid Zip Code!",
  zip_empty: "Please Enter Zip Code",
  phone_invalid: "Invalid Mobile Number!",
  phone_empty: 'Please Enter Mobile Number',
  email_invalid: "Invalid Email ID!",
  email_empty: '',
  homeStyle_empty: 'Please select a Home Style to proceed further',
  roofType_empty: 'Please select a Roof material to proceed further',
  service_plan_empty: 'Please select a service plan to proceed further',
  listServices_empty: 'Please Select a Service to proceed further',
  priceConfig2_empty: 'Please answer the questions to see the prices',
  priceConfig3_empty: 'Please answer the Additional Information Required to proceed',
  firstname_empty: "Please Enter First Name",
  firstname_invalid: "Invalid First Name!",
  lastname_empty: "Please Enter Last Name",
  lastname_invalid: "Invalid Last Name!",
  password_empty: "Please Enter Password",
  password_invalid: "Weak Password! Your password must be at least 8 and max 30 characters long and include at least one lowercase letter, one uppercase letter, one digit, and one special character.",
  conf_password_empty: "Please Enter Password",
  conf_password_invalid: "Please make sure your passwords match",
  login_empty: "Please Enter Email or Phone Number",
  card_empty: 'Credit Card Number is required',
  card_invalid: 'Invalid Credit Card Number',
  cvc_empty: 'Cvc is required',
  cvc_invalid: 'Invalid Cvc',
  addr_empty: 'Please Enter Address',
  addr_invalid: 'Invalid Address',
  name_empty: "Please Enter Name",
  name_invalid: "Invalid Name!",
  apt_empty: "Please Enter Apt/Suite",
  apt_invalid: "Invalid Apt!",
  city_empty: "Please Enter City",
  city_invalid: "Invalid City!",
  street_empty: "Please Enter Street",
  street_invalid: "Invalid Street!",
  streetNumber_empty: "Please Enter Street Number",
  streetNumber_invalid: "Invalid Street Number!",
  state_empty: "Please Select State",
  fullName_empty: 'Please Enter Full Name',
  fullName_invalid: 'Invalid Full Name!',
  street_empty: "Please Enter Street",
  street_invalid: "Invalid Street!",
};

async function PostRemovedServices() {

    const storedServices = localStorage.getItem('trackedServices');
    let parsedServices = [];

    if (storedServices) {
        //convert to the right format
        parsedServices = JSON.parse(storedServices);
        if (!Array.isArray(parsedServices)) {
            parsedServices = [parsedServices];
        }

    }

    const allServices = localStorage.getItem('allServices');
    let allServicesArray = [];


    // Parse allServices if it exists
    if (allServices) {
        allServicesArray = JSON.parse(allServices);
    }

    if (parsedServices.length === 0) {
        // If parsedServices is empty, take everything from allServicesArray
        parsedServices = [...allServicesArray];
    }

    else {

        parsedServices.forEach(newSite => {
            // Find if the siteID already exists in allServices
            const existingSite = allServicesArray.find(site => site.siteID === newSite.siteID);

            if (existingSite) {
                // If the site exists, merge the services (avoid duplicates)
                newSite.Services.forEach(service => {
                    if (!existingSite.Services.some(existingService => existingService.name === service.name)) {
                        existingSite.Services.push(service);
                    }
                });
            } else {
                // If the site doesn't exist, add the entire site to allServices
                allServicesArray.push(newSite);
            }
        });
    }


    try {
        const bodyData = {
            sites: allServicesArray
        };

        //alert(JSON.stringify(bodyData));

        const response = await fetch(`/api/Services/PostRemovedService`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        });
        if (response?.status === 401) {
            window.location.href = "/";
        }

        if (!response.ok) {
            const text = await response.text();
            setApiError(text);

        }

        const data = await response.json();
        if (data?.responseCode === 200) {

            //setServiceBookedPopup(true);
        }
    } catch (err) {

    }

    localStorage.setItem('allServices', '');
    localStorage.setItem('services', '');
    localStorage.setItem('trackedServices', '');
    localStorage.setItem('checkout', '');
}

export const logoutUser = async () => { 

    const checkout = localStorage.getItem('checkout');

    if(checkout == null || checkout == '')
    PostRemovedServices();
    const bodyobj = {
      token: localStorage.getItem("token")
    }
    try {
      const response = await fetch(`/api/Customer/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyobj)
      });

      if (response?.status === 401) {
        window.location.href = "/login"
      }

      const data = await response.json();
      if (data?.responseCode === 200) {
        localStorage.setItem("token", "")
        window.location.href = "/login"
      }
    } catch (error) {
    }
  }
import serviceMain1 from "../../assets/images/service-main-1.svg";
import serviceMain2 from "../../assets/images/service-main-2.svg";
import serviceMain3 from "../../assets/images/service-main-3.svg";
import serviceMain4 from "../../assets/images/service-main-4.svg";
import service1 from "../../assets/images/service-1.svg";
import service2 from "../../assets/images/service-2.svg";
import service3 from "../../assets/images/service-3.svg";
import service4 from "../../assets/images/service-4.svg";
import service5 from "../../assets/images/service-5.svg";
import service6 from "../../assets/images/service-6.svg";
import service7 from "../../assets/images/service-7.svg";
import service8 from "../../assets/images/service-8.svg";
import service9 from "../../assets/images/service-9.svg";
import homestyle1 from "../../assets/images/home-style-1.svg";
import homestyle2 from "../../assets/images/home-style-2.svg";
import homestyle3 from "../../assets/images/home-style-3.svg";
import homestyle4 from "../../assets/images/home-style-4.svg";

export const serviceIcons = [
  {
    imageUrl: service1,
    altText: "Gutter Cleaning",
    serviceName: "Gutter<br />Cleaning",
  },
  {
    imageUrl: service2,
    altText: "Full Gutter Installation",
    serviceName: "Full Gutter<br />Installation",
  },
  {
    imageUrl: service3,
    altText: "Roof Cleaning",
    serviceName: "Roof Cleaning",
  },
  {
    imageUrl: service4,
    altText: "Siding Cleaning",
    serviceName: "Siding Cleaning",
  },
  {
    imageUrl: service5,
    altText: "Window Cleaning",
    serviceName: "Window<br /> Cleaning",
  },
  {
    imageUrl: service6,
    altText: "Deck Cleaning",
    serviceName: "Deck Cleaning",
  },
  {
    imageUrl: service7,
    altText: "Walkway Cleaning",
    serviceName: "Walkway<br /> Cleaning",
  },
  {
    imageUrl: service8,
    altText: "Fence Cleaning",
    serviceName: "Fence<br /> Cleaning",
  },
  {
    imageUrl: service9,
    altText: "Mosquito & Tick",
    serviceName: "Mosquito & Tick",
  },
];

export const homeStyles = [
  {
    id: 1,
    icon: homestyle1,
    title: "Cape Cod",
    description:
      "The Cape-style house, is a rectangular frame house with a steeply pitched roof, a large central chimney, and a symmetrical design.",
    height: 100
  },
  {
    id: 2,
    icon: homestyle2,
    title: "Two-Level",
    description:
      "The two-level style house has two floors. Typically this means a ground-level floor and a second-story.",
    height: 100
  },
  {
    id: 3,
    icon: homestyle3,
    title: "Colonial",
    description:
      "A Colonial-style home typically has a rectangle shape and a central entrance with symmetrical, uniformly sized windows.",
    height: 100
  },
  {
    id: 4,
    icon: homestyle4,
    title: "Ranch",
    description:
      "Traditional Ranch-style homes are single-story houses with an open-concept layout. Typically, these homes feature long, low-pitched roof.",
    height: 120
  },
];
export const popularServiceIcons = [
  {
    id: 1,
    imageUrl: serviceMain1,
    altText: "Gutter Cleaning",
    serviceName: "Gutter Cleaning",
    mode: "button",
    value: 150,
    Basevalue: 200,
    servicePlane:'Diamond Plan – 6 Services',
  },
  {
    id: 2,
    imageUrl: serviceMain2,
    altText: "Window Cleaning",
    serviceName: "Window Cleaning",
    mode: "button",
    value: 250,
    Basevalue: 50,
    servicePlane:"One-Time Service",
  },
  {
    id: 3,
    imageUrl: serviceMain3,
    altText: "Roof Cleaning",
    serviceName: "Roof Cleaning",
    mode: "button",
    value: 360,
    Basevalue: 60,
    servicePlane:"One-Time Service",
  },
  {
    id: 4,
    imageUrl: serviceMain4,
    altText: "Gutter Guard Removal",
    serviceName: "Gutter Guard <br /> Removal",
    mode: "button",
    value: 280,
    Basevalue: 80,
    servicePlane:"One-Time Service",
  },
];

export const additionalServices = [
  {
    id: 5,
    altText: "Siding Cleaning",
    serviceName: "Siding Cleaning",
    imageUrl: service4,
    mode: "button",
    value: 120,
    Basevalue: 120,
    servicePlane:"One-Time Service",
  },
  {
    id: 6,
    altText: "Gutter Installation & Repair",
    serviceName: "Gutter Installation & Repair",
    imageUrl: service2,
    mode: "button",
    value: 130,
    Basevalue: 90,
    servicePlane:"One-Time Service",
  },
  {
    id: 7,
    altText: "Deck Cleaning",
    serviceName: "Deck Cleaning",
    imageUrl: service6,
    mode: "button",
    value: 150,
    Basevalue: 150,
    servicePlane:"One-Time Service",
  },
  {
    id: 8,
    altText: "Walkway Cleaning",
    serviceName: "Walkway Cleaning",
    imageUrl: service7,
    mode: "price",
    value: 120,
    Basevalue: 120,
    servicePlane:"One-Time Service",
  },
  {
    id: 9,
    altText: "Fence Cleaning",
    serviceName: "Fence Cleaning",
    imageUrl: service8,
    mode: "price",
    value: 280,
    Basevalue: 80,
    servicePlane:"One-Time Service",
  },
  {
    id: 10,
    altText: "Mosquito & Tick",
    serviceName: "Mosquito & Tick",
    imageUrl: service9,
    mode: "button",
    value: 200,
    Basevalue: 200,
    servicePlane:'Diamond Plan – 6 Services',
  },
  {
    id: 11,
    altText: "Dryer Vent Cleaning",
    serviceName: "Dryer Vent Cleaning",
    imageUrl: service2,
    mode: "button",
    value: 270,
    Basevalue: 70,
    servicePlane:"One-Time Service",
  },
];

export const AddOns = [
  {
    id: 1,
    addons: "Gutter Protection",
    value: 20,
  },
  {
    id: 2,
    addons: "Chimney Cap",
    value: 49,
  },
  {
    id: 3,
    addons: "DVC",
    value: 99,
  },
];

export const PropertyAcreage = [
  {
    id: 1,
    acrege: "Under 1",
  },
  {
    id: 2,
    acrege: "1-2",
  },
  {
    id: 3,
    acrege: "2-3",
  },
  {
    id: 4,
    acrege: "Over 3",
  },
];

export const NumberofRooms = [
  {
    id: 1,
    label: "1",
  },
  {
    id: 2,
    label: "2",
  },
  {
    id: 3,
    label: "3",
  },
  {
    id: 4,
    label: "4",
  },
  {
    id: 5,
    label: "5+",
  },
];

export const RoofMaterial = [
  {
    id: 1,
    label: "Metal",
  },
  {
    id: 2,
    label: "Tile",
  },
  {
    id: 3,
    label: "Asphalt Shingle",
  },
  {
    id: 4,
    label: "Slate of Stone",
  },
  {
    id: 5,
    label: "Other",
  },
];

export const roomNum = [
  {
    id: 1,
    label: 1,
  },
  {
    id: 2,
    label: 2,
  },
  {
    id: 3,
    label: 3,
  },
  {
    id: 4,
    label: 4,
  },
  {
    id: 5,
    label: "5+",
  },
];

export const roofMaterial = [
  {
    id: 1,
    label: "Metal",
  },
  {
    id: 2,
    label: "Tile",
  },
  {
    id: 3,
    label: "Asphalt Shingle",
  },
  {
    id: 4,
    label: "Slate of Stone",
  },
  {
    id: 5,
    label: "Other",
  },
];

export const propAcreage = [
  {
    id: 1,
    label: "Under 1",
  },
  {
    id: 2,
    label: "1-2",
  },
  {
    id: 3,
    label: "2-3",
  },
  {
    id: 4,
    label: "Over 3",
  },
];
export const roomSelection = [
  {
    id: 1,
    label: "Gutter Cleaning",
  },
  {
    id: 2,
    label: "Window Cleaning",
  },
];

export const footageSelection = [
  {
    id: 1,
    label: "Roof Cleaning",
  },
  {
    id: 2,
    label: "Siding Cleaning",
  },
];
export const materialSelection = [
  {
    id: 1,
    label: "Roof Cleaning",
  },
  {
    id: 2,
    label: "Siding Cleaning",
  },
];
export const fenceFootageSelection = [
  {
    id: 1,
    label: "Roof Cleaning",
  },
  {
    id: 2,
    label: "Siding Cleaning",
  },
];
export const deckAreaSelection = [
  {
    id: 1,
    label: "Roof Cleaning",
  },
  {
    id: 2,
    label: "Siding Cleaning",
  },
];
export const walkwayAreaSelection = [
  {
    id: 1,
    label: "Roof Cleaning",
  },
  {
    id: 2,
    label: "Siding Cleaning",
  },
];
export const acreageSelection = [
  {
    id: 1,
    label: "Roof Cleaning",
  },
  {
    id: 2,
    label: "Siding Cleaning",
  },
];
export const gutterSelection = [
  {
    id: 1,
    label: "Roof Cleaning",
  },
  {
    id: 2,
    label: "Siding Cleaning",
  },
];

export const upcomingServices = [
  {
    id: 1,
    label: "Full Gutter Installation",
  },
  {
    id: 2,
    label: "Walkway Cleaning",
  }, {
    id: 3,
    label: "Fence Cleaning",
  },
  {
    id: 4,
    label: "Mosquito & Tick",
  }, {
    id: 5,
    label: "Dryer Vent Cleaning",
  },
  {
    id: 6,
    label: "Gutter Guard Removal",
  },
];

export const phoneNumberConstant = '(888) 299-4524';

export const phoneNumberConstantCall = 'tel:8882994524';
import React from 'react'
import loaderGif from "../../assets/images/kOnzy.gif"

function LoaderSpinner() {
    return (
        <div
            className="relative"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" style={{ zIndex: 1002 }}></div>

            <div className="fixed inset-0 w-screen overflow-y-auto p-4" style={{ zIndex: 1003 }}>
                <div className="flex min-h-full items-center justify-center">
                    <div className="">
                        <div className=" px-7 py-8">
                            <img src={loaderGif} alt={loaderGif} width="50px" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoaderSpinner